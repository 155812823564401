// libs
import React from "react";

// styles
import "./footer.styles.scss";

const Footer = () => {
  return <footer>&copy; {new Date().getFullYear()} - VALERIA CASTILLO </footer>;
};

export default Footer;
