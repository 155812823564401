// libs
import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// styles
import "./socials.styles.scss";

/**Functional Component */
const Socials = ({ socialUrl, socialName, socialIcon }) => {
  return (
    <a
      download
      className="socials__link"
      href={socialUrl}
      title={socialName}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={socialIcon} />
    </a>
  );
};

export default Socials;
