// libs
import React from "react";

// styles
import "./card-list.styles.scss";

// components
import Card from "../card/card.component";

const CardList = items => {
  return (
    <div className="cards">
      <h2>
        Projects <span className="cards__header--right">→</span>
        <span className="cards__header--down">↓</span>
      </h2>

      <div className="card-list">
        <div className="card-list__items">
          {items.items.map(items => (
            <Card
              key={items.id}
              title={items.title}
              imageUrl={items.imageUrl}
              description={items.description}
              stackIcons={items.stackIcons}
              siteUrl={items.siteUrl}
              githubUrl={items.githubUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardList;
