import {
  faGithub,
  faLinkedin,
  faCodepen,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFileDownload } from "@fortawesome/free-solid-svg-icons";

const SOCIALS_DATA = [
  {
    id: 1,
    socialName: "Download My Resume",
    socialUrl: "/ValeriaCastillo_SoftwareEngineer_Resume.pdf",
    socialIcon: faFileDownload,
  },
  {
    id: 2,
    socialName: "Email",
    socialUrl: "mailto:valcastillodev@gmail.com",
    socialIcon: faEnvelope,
  },
  {
    id: 3,
    socialName: "Github",
    socialUrl: "https://github.com/vcastle/",
    socialIcon: faGithub,
  },
  {
    id: 4,
    socialName: "LinkedIn",
    socialUrl: "https://www.linkedin.com/in/vcswe/",
    socialIcon: faLinkedin,
  },
  {
    id: 5,
    socialName: "Codpen",
    socialUrl: "https://codepen.io/vcastle/",
    socialIcon: faCodepen,
  },
];

export default SOCIALS_DATA;
