// libs
import React, { useState } from "react";

// components
import Header from "./components/header/header.component";
import CardList from "./components/card-list/card-list.component";
import Footer from "./components/footer/footer.component";

// utils
import { INTRO_DESCRIPTION, INTRO_HEADER } from "./utils/constants";

// data
import ITEM_DATA from "./components/card-list/data/items.data";
import SOCIALS_DATA from "./components/socials/data/socials.data";

// imgs
import valLogo from "./assets/images/vclogo.png";

function App() {
  const [items] = useState(ITEM_DATA);
  const [socials] = useState(SOCIALS_DATA);

  return (
    <div className="grid">
      <Header
        socials={socials}
        headerText={INTRO_HEADER}
        headerBody={INTRO_DESCRIPTION}
      ></Header>

      <CardList items={items}></CardList>

      <div className="footer">
        <img src={valLogo} className="footer__logo" alt="Val's Logo" />
        <Footer></Footer>
      </div>
    </div>
  );
}
export default App;
