import {
  faReact,
  faSass,
  faAngular,
  faBootstrap,
  faCss3,
  faHtml5,
  faJsSquare,
} from "@fortawesome/free-brands-svg-icons";

const ITEM_DATA = [
  {
    id: 1,
    title: "pokedex",
    imageUrl:
      "https://user-images.githubusercontent.com/30732277/107870094-91578780-6e4a-11eb-9d72-166782f49145.png",
    description: "Search for your favorite 1st generation Pokemon.",
    stackIcons: [faReact, faSass],
    siteUrl: "https://vcastle.github.io/pokedex/",
    githubUrl: "https://github.com/vcastle/pokedex",
    category: "react",
  },
  {
    id: 2,
    title: "great minds",
    imageUrl:
      "https://user-images.githubusercontent.com/30732277/106701427-7dcf3580-659b-11eb-83e6-6a68f6afe318.png",
    description: "Suggestion Box for your great ideas.",
    stackIcons: [faAngular, faSass],
    siteUrl: "https://greatminds-thinkalike.web.app/",
    githubUrl: "https://github.com/vcastle/greatmindsbox",
    category: "angular",
  },
  {
    id: 3,
    title: "chateau du nala",
    imageUrl:
      "https://user-images.githubusercontent.com/30732277/106701560-b707a580-659b-11eb-8d97-c86b4eea6df4.png",
    description: "Take your coffee orders at Château de Nala!",
    stackIcons: [faAngular, faBootstrap, faCss3],
    siteUrl: "https://github.com/vcastle/ChateauChatCafe",
    githubUrl: "https://github.com/vcastle/pokedex",
    category: "angular",
  },
  {
    id: 4,
    title: "sprintspo",
    imageUrl:
      "https://user-images.githubusercontent.com/30732277/106701713-f59d6000-659b-11eb-986d-6138c4b1aeea.png",
    description: "Random quote generator for your next sprint.",
    stackIcons: [faJsSquare, faCss3, faHtml5],
    siteUrl: "https://sprintspo.web.app/",
    githubUrl: "https://github.com/vcastle/sprintspo",
    category: "javaScript",
  },
];

export default ITEM_DATA;
