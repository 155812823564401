// libs
import React, { useState, useRef } from "react";

// icons
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./card.styles.scss";

function Card({
  title,
  imageUrl,
  description,
  stackIcons,
  siteUrl,
  githubUrl,
}) {
  const [flip, setFlip] = useState(false);

  const frontEl = useRef();
  const backEl = useRef();

  return (
    <div
      className={`card ${flip ? "card__flip" : ""}`}
      onClick={() => setFlip(!flip)}
    >
      <div
        className="card__front up"
        style={{ backgroundImage: "url(" + imageUrl + ")" }}
        ref={frontEl}
      >
        <div className="card__details">
          <a
            title={title}
            href={siteUrl}
            target="_blank"
            rel="noreferrer"
            className="card__title"
          >
            {title}
          </a>
          <a
            className="card__link"
            target="_blank"
            rel="noreferrer"
            href={githubUrl}
          >
            <FontAwesomeIcon icon={faGithub}></FontAwesomeIcon>
          </a>
        </div>
      </div>

      <div className="card__back" ref={backEl}>
        <div className="card__description">{description}</div>
        <div className="card__stack-icons">
          {stackIcons.map(stackIcons => (
            <FontAwesomeIcon icon={stackIcons} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Card;
