// libs
import React from "react";

// styles
import "./header.styles.scss";

// imgs
import val from "../../assets/images/val.png";
import valLogo from "../../assets/images/vclogo.png";

// components
import Socials from "../socials/socials.component";
import Footer from "../footer/footer.component";

const Header = ({ socials, headerText, headerBody }) => {
  return (
    <div className="header">
      <div className="header__profile">
        <img
          className="header__img"
          alt="Illustration of Valeria Castillo"
          src={val}
        ></img>
        <div className="profile-details">
          <h1 className="profile-details__title">{headerText}</h1>
          <p className="profile-details__body">{headerBody}</p>

          <div className="profile-details__socials">
            {socials.map(socials => (
              <Socials
                key={socials.id}
                socialUrl={socials.socialUrl}
                socialName={socials.socialName}
                socialIcon={socials.socialIcon}
              />
            ))}
          </div>

          <div className="profile-details__footer">
            <img
              className="profile-details__logo"
              src={valLogo}
              alt="Val's Logo"
            />
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
